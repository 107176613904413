/* ============ APP SHELL ============ */
html,
body,
#root {
    width: 100%;
    height: 100%;
    margin: 0;
}
body,
#root {
    display: flex;
}
body {
    font-family: roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* TODO @dorian remove that crap */
    /* background-color: #f3f4f6; */
}

/* ============ HELPERS ============ */

/* HELPERS */
.inline-flex { display: inline-flex }
code { font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; }
.h100 { height: 100%; }
.relative {position: relative;}
.absolute {position: absolute;}
.inset-0 {top:0; left:0;right:0;bottom: 0;}
.scroll {overflow: auto;}
.wrap {flex-wrap: wrap;}
.row { display: flex; flex-direction: row; }
.col { display: flex; flex-direction: column; }
.col-rev { display: flex; flex-direction: column-reverse; }
.line { display: flex; flex-direction: row; align-items: baseline; }
.line2 { display: flex; flex-direction: row; align-items: center; }
.line3 { display: flex; flex-direction: row; align-items: flex-end; }
.s100 { width: 100%; height: 100%; }
.text-small { font-size: 0.875rem; }
.text-underline:hover { text-decoration: underline; }
._bord { border: 1px dashed black; }
.basis-0 { flex-basis: 0; }
.basis-1 { flex-basis: 1px; }
.grow { flex-grow: 1; }
.RED { color: red; }
.pointer { cursor: pointer; }
.italic { font-style: italic; }
.self-start { align-self: flex-start; }

/* CORE LAYOUT SETUP */

.MuiPaper-root.page{padding-left: 1rem;}

.splitContainer > * { flex-shrink: 0; }
@media (max-width: 768px) {
    .splitContainer { flex-direction: column; }
}

/* ============ CORE OVERWRITES ============ */
nav > ul { display: flex; flex-direction: row; }
nav > ul > li { margin: 0.1rem 1rem; }
code > pre {
    word-break: break-all;
    white-space: pre-wrap;
    font-size: 0.8em;
    background-color: gray;
}
h1 { font-size: 1.8rem; }
h2 { font-size: 1.5rem; }
h3 { font-size: 1.2rem; }
h4 { font-size: 1.2rem; }
h1, h2, h3, h4, h5, p {
    margin: 0 0 0.3rem 0;
    padding: 0;
}

/* ul { padding: 0; list-style: none; } */
ul {
    margin-block-start: .2rem;
    margin-block-end: .2rem;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 1rem;
}

button {
    border: none;
    background: inherit;
    padding: 0;
    cursor: pointer;
}

/* ============ APP ============ */
.MuiDataGrid-row { cursor: pointer; }
.stack-line {
    /* display: flex; */
    /* align-items: center; */
    padding: 0.2rem;
    transition: 0.3s;
    cursor: pointer;
    line-height: normal;
    /* background: #ffffff; */
    /* box-shadow: 0 5px 20px -5px lightgray; */
    /* margin-bottom: 0.5rem; */
    border-radius: 0.25rem;
    /* border: 1px solid #f3f4f6; */
    /* font-weight: 500; */
}

.stack-line .id {
    font-weight: normal;
    font-style: italic;
    margin-left: 0.25rem;
    font-size: 1rem;
}

.stack-line:hover {
    background: #f3f4f6;
}

.id { font-size: 0.75rem; font-weight: bold; }
.status-icon-cell { display: flex; align-items: center; }

/* ============ login popup ============ */
.loginPopupWrapper { background: gray; }
.loginPopup > * { margin-bottom: 1rem; }
.loginPopup > button { padding: 0.2rem; }
.loginPopup {
    margin: auto;
    border: 1px solid gray;
    padding: 2rem 1rem;
    background-color: #fcfcfc;
}

/* ============ MISC ============ */
.material-icons { font-size: 1em; }
.indicator-battery {
    width: auto;
    height: 100%;
    border-radius: 0.25rem;
    padding: 2px 0.5rem;
    display: block;
    text-align: center;
}

.indicator-battery svg { width: 1rem; height: 1rem; }

.editable-fields input { text-align: right; }
.MuiCollapse-root .MuiList-root { padding-left: 1rem; }


/* Menu */
.menu-icon svg {
    width: 1.25rem;
    height: 1.25rem;
    margin: 0 auto;
}



/* ============ REACT DATAGRID  ============ */
.rdg-caret { display: inline; }
.rdg {
    -webkit-user-select: text !important;
    -webkit-touch-callout: default !important;
    -webkit-user-select: text !important;
    -moz-user-select: text !important;
    -ms-user-select: text !important;
    user-select: text !important;
}

/* .splitContainer > div { padding: 0.5rem 1rem 0; } */
.splitContainer > div:first-child { padding: 0rem .8rem 0; }

/*  */
.propertyPaper {padding: .5rem; margin: 0 .5rem .5rem 0 ; flex-grow: 1;}
.propertyGroup { margin: .5rem 0; }
.propertyGroup > div { margin-bottom: 0.3rem; }
.propertyName::after { content: ':'; }
.propertyName {
    font-weight: 'semibold';
    margin-right: 0.5rem;
    color: #888888;
}

/* Homepage */
@media screen and (max-width: 768px) {
    .HomePage { flex-direction: column-reverse; }
    .CarteUI { height: 80vh; }
}
h1 > svg { margin-right: 0.5rem; }
h2 > svg { margin-right: 0.4rem; }
h3 > svg { margin-right: 0.3rem; }

.icon-UploadParcel { transform: scaleY(-1); }

/* .sidePanel .sidePanel-close-btn {
    position: absolute;
    right: -100px;
    z-index: 9999;
} */

.sidePanel section,
.info-page section {
    margin: 1rem 0;
}

.sh-key{color:white; background-color: rgb(92, 92, 92); }
.THEME-LIGHT .sh-action {color:#8d2cd0; } /* background-color: #ffffff;}*/
.THEME-DARK .sh-action {color:#8d2cd0;  } /* background-color: #25292a; }*/
.sh-key, .sh-action { font-weight: bold; }

.THEME-DARK a { color: #a7a730; }
.THEME-DARK .row-selected { background: #3d6943; }
.THEME-LIGHT .row-selected { background: #dce6dd; }

.THEME-DARK .MuiPaper-root.carte-panel {background-color: #1f1f1f;}


.THEME-LIGHT .MuiPaper-root.carte-panel {background-color: #f0f0f0;}
.THEME-LIGHT .propertyPaper {background-color: #f0f0f0;}


.THEME-DARK .col-time {background-color: black;}
.THEME-LIGHT .col-time {background-color: white;}
/* .THEME-DARK a:visited { color: green; } */


.TagUI {
    display: inline;
    padding: 0 8px;
    border-radius: 1rem;
    margin-right: .3rem;
}

.rdg-cell-frozen{
    position: unset !important;
}

.CorridorInfoUI * {
    box-sizing: border-box;
}
.CorridorInfoUI .segment-name {
    width: 2rem;
}
.CorridorInfoUI .segment-lat,
.CorridorInfoUI .segment-lon{
    width: 6rem
}
.CorridorInfoUI .segment-speed{
    width: 3rem
}

.reverse-chronological,
.reverse-chronological .stepDetail {
    display: flex;
    flex-direction: column-reverse;
}